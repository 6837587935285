.MobileFilterForm {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: hsl(0, 0%, 10%);
  animation-name: mobileFormEnter;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  z-index: 10;
}

.MobileFilterContent {
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  margin: auto;
  overflow: auto;
}

.MobileFilterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: hsl(0, 0%, 7%);
  border-bottom: 1px solid hsl(0, 0%, 25%);
  height: 50px;
  padding: 0 16px;
}

.MobileFilterClose {
  font-size: 32px;
  line-height: 0.8;
  padding: 5px;
  cursor: pointer;
}

.MobileFilterFooter {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(0, 0%, 7%);
  border-top: 1px solid hsl(0, 0%, 40%);
}

@keyframes mobileFormEnter {
  0% {
    transform: translateY(5%);
    transition: transform 0.5s ease-in-out;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
