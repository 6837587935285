.button {
  display: inline-block;
  /* width: 100%; */
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 70%);
  /* font-weight: bold; */
  font-size: 0.8em;
}

.button:hover {
  opacity: 0.9;
}

.button:active {
  opacity: 0.8;
}

.button.primary {
  background-color: snow;
  color: hsl(0, 0%, 10%);
}

.button.secondary {
  background-color: transparent;
  color: snow;
  border: 1px solid snow;
}
