
.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  z-index: -100;
  opacity: 0.1;
}
