.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: hsla(0, 0%, 0%, 0.8);
}

.Modal.hidden {
  visibility: hidden;
}
