.StatSummary {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.StatSummaryColumn {
  text-align: center;
  margin: 0 8px;
}

.StatSummaryValue {
  font-size: 22px;
}

@media screen and (max-width: 1200px), screen and (max-height: 500px) {
  .StatSummaryLabel {
    font-size: 14px;
  }
  .StatSummaryValue {
    font-size: 16px;
  }
}
