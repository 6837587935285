.Header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 7%);
  padding: 0 16px;
  border-bottom: 1px solid hsl(0, 0%, 21%);
  z-index: 10;
  box-shadow: 0 1px 2px 0 hsla(0, 0%, 0%, 70%);
  /* border: 1px solid red; */
  width: 100vw;
  max-width: 100%;
}

.HeaderLogo {
  height: 32px;
}

@media screen and (max-width: 1200px), screen and (max-height: 500px) {
  .HeaderLogo {
    height: 22px;
  }
}
