.Footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
}

.Footer * {
  color: snow;
}

.Footer .icons {
  font-size: 32px;
  margin-top: 0.1em;
}

.Footer .icons a {
  margin: 0.1em;
}

@media screen and (max-width: 1200px), screen and (max-height: 500px) {
  .Footer {
    font-size: 12px;
  }
  .Footer .icons {
    font-size: 28px;
  }
}
