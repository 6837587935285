.sidebar {
  box-sizing: border-box;
  position: fixed;
  height: 100%;
}

.SimpleBar {
  height: 100%;
  overflow: auto;
  padding: 8px;
}
