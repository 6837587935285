.PercentageDisplay {
  border: solid 1px hsl(0, 0%, 28%);
  border-radius: 6px;
  background-color: hsla(0, 0%, 14%, 80%);
  padding: 8px 4px;
  margin: 3px 2px;
}

.Percentages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.PercentageDisplayDiv {
  margin: 2px 0;
  padding: 3px 5px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
}

.PercentageDisplayDiv.selected {
  border-color: snow;
}

.Percentage {
  font-size: 18px;
  color: hsl(0, 0%, 78%);
}

.Percentage .percentSign {
  font-size: 0.8em;
}

.PercentageDisplay .title {
  font-weight: normal;
  font-size: 18px;
  margin: 8px 0;
}

@media screen and (max-width: 1200px), screen and (max-height: 500px) {
  .PercentageDisplayDiv {
    font-size: 14px;
  }
  .Percentage {
    font-size: 16px;
  }
  .PercentageDisplay .title {
    font-size: 16px;
  }
}
