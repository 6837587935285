.Dropdown {
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  color: snow;
  border: 1px solid hsl(0, 0%, 42%);
  background-color: hsl(0, 0%, 28%);
}

.DropdownChevronPositioner {
  position: relative;
  float: right;
  height: 0;
  top: -26px;
  left: -5px;
}

.Dropdown option:disabled {
  color: #c9c3c3;
}
